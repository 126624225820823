import axios from '@axios'
import { getCurrentInstance } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import ability from '@/plugins/acl/ability'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('userData')),
    settings: JSON.parse(localStorage.getItem('lbe@settings')) || {},
    dashboards: JSON.parse(localStorage.getItem('lbe@dashboards')) || [],
    shallContentShowOverlay: false,
    rowsPerPage: [
      { value: 5, label: i18n.t('5') },
      { value: 10, label: i18n.t('10') },
      { value: 15, label: i18n.t('15') },
      { value: 25, label: i18n.t('25') },
      { value: 50, label: i18n.t('50') },
      { value: 100, label: i18n.t('100') },
      { value: -1, label: i18n.t('Tous') },
    ],
    textQualities: [
      { id: 4, label: 'QAD', title: 'QAD - Qualité à determiner' },
      { id: 3, label: 'TQS ', title: 'TQS - Texte de Qualité Supérieur' },
      { id: 1, label: 'TQ', title: 'TQ - Texte de Qualité' },
      { id: 2, label: 'TM', title: 'TM - Texte Moyen' },
      { id: 0, label: 'TS', title: 'TS - Texte Simple' },
      { id: -1, label: 'TSP', title: 'TSP - Texte Simple Plus' },
    ],
    pays: JSON.parse(localStorage.getItem('lbe@pays')) || [],
    sources: JSON.parse(localStorage.getItem('lbe@sources')) || [],
    origins: JSON.parse(localStorage.getItem('lbe@origins')) || [],
    statuses: JSON.parse(localStorage.getItem('lbe@statuses')) || [],
    genres: JSON.parse(localStorage.getItem('lbe@genres')) || [],
    destinations: JSON.parse(localStorage.getItem('lbe@destinations')) || [],
    printers: JSON.parse(localStorage.getItem('lbe@printers')) || [],
    formats: JSON.parse(localStorage.getItem('lbe@formats')) || [],
    colors: JSON.parse(localStorage.getItem('lbe@colors')) || [],
    states: {
      CREATED_STATE: 0,
      SEND_STATE: 1,
      RESEND_STATE: 2,
      SIGNED_STATE: 3,
      COLLECTED_STATE: 4,
      TEXT_STATE: 5,
      COVER_STATE: 6,
      SUPERVISOR_STATE: 7,
      PUBLICATION_STATE: 8,
      RESILIATION_STATE: 9,
      PAYMENT_STATE: 9,
    },
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    APPLY_SETTINGS(state, value) {
      state.settings = value !== undefined ? value : {}
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    init: ({ state, dispatch }) => {
      if (state.user) {
        // eslint-disable-next-line no-undef
        axios.get('/profile', {}).then(response => {
          if (response.data.login) {
            if (process.env.pro) {
              dispatch('logout')
            }

            return
          }
          if (response.data && response.data.data && response.data.data.id) {
            dispatch('setUser', response.data.data)
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        dispatch('logout')
      }
      axios.get('/system/settings', {}).then(response => {
        // eslint-disable-next-line no-param-reassign
        var res = response.data
        if (res.visual_idea_colors_ids) {
          res.visual_idea_colors_ids = res.visual_idea_colors_ids.split(',')
          res.visual_idea_colors_ids = res.visual_idea_colors_ids.map(ele => parseInt(ele))
        } else {
          res.visual_idea_colors_ids = []
        }
        if (res.author_copy_order_colors_ids) {
          res.author_copy_order_colors_ids = res.author_copy_order_colors_ids.split(',')
          res.author_copy_order_colors_ids = res.author_copy_order_colors_ids.map(ele => parseInt(ele))
        } else {
          res.author_copy_order_colors_ids = []
        }
        if (res.communication_colors_ids) {
          res.communication_colors_ids = res.communication_colors_ids.split(',')
          res.communication_colors_ids = res.communication_colors_ids.map(ele => parseInt(ele))
        } else {
          res.communication_colors_ids = []
        }
        if (res.order_colors_ids) {
          res.order_colors_ids = res.order_colors_ids.split(',')
          res.order_colors_ids = res.order_colors_ids.map(ele => parseInt(ele))
        } else {
          res.order_colors_ids = []
        }
        if (res.manuscript_colors_ids) {
          res.manuscript_colors_ids = res.manuscript_colors_ids.split(',')
          res.manuscript_colors_ids = res.manuscript_colors_ids.map(ele => parseInt(ele))
        } else {
          res.manuscript_colors_ids = []
        }
        if (res.manuscript_me_denied_statuses) {
          res.manuscript_me_denied_statuses = res.manuscript_me_denied_statuses.split(',')
          res.manuscript_me_denied_statuses = res.manuscript_me_denied_statuses.map(ele => parseInt(ele))
        } else {
          res.manuscript_me_denied_statuses = []
        }

        state.settings = res
        state.settings.manuscript_approuved_status = parseInt(state.settings.manuscript_approuved_status, 10) || null
        state.settings.publication_signed_status = parseInt(state.settings.publication_signed_status, 10) || null
        state.settings.publication_bat_status = parseInt(state.settings.publication_bat_status, 10) || null
        state.settings.default_country_id = parseInt(state.settings.default_country_id, 10) || 79
        store.state.app.settings.publication_bat_status = parseInt(state.settings.publication_bat_status, 10) || null
        state.settings.publication_bod_status = parseInt(state.settings.publication_bod_status, 10) || null
        state.settings.white_color_id = parseInt(state.settings.white_color_id, 10) || null
        state.settings.green_color_id = parseInt(state.settings.green_color_id, 10) || null
        state.settings.light_green_color_id = parseInt(state.settings.light_green_color_id, 10) || null
        state.settings.publication_value = state.settings.publication_value || null
        window.localStorage.setItem('lbe@settings', JSON.stringify(response.data))
      }).catch(error => {
        console.log(error)
      })
      dispatch('fetchDashboard')
      dispatch('fetchCountries')
      dispatch('fetchStatuses')
      dispatch('fetchSources')
      dispatch('fetchOrigins')
      dispatch('fetchGenres')
      dispatch('fetchColors')
      dispatch('fetchFormats')
    },
    logout: ({ state }) => {
      window.localStorage.removeItem('userData')
      window.localStorage.removeItem('userAbility')
      state.login = false
      state.user = {}
      // eslint-disable-next-line no-undef
      axios.get('/logout').then(() => {})
      if (router.currentRoute.name !== 'auth-login') {
        router.push({ name: 'auth-login' })
      }
    },
    // eslint-disable-next-line no-unused-vars
    setUser: ({ state }, userData) => {
      const user = userData/* {
        ...userData,
        /!*avatar: require('@/assets/images/avatars/1.png'),*!/
        /!*permissions: [/!* 'edit-article', 'view-role', *!/'read-ACL', 'read-Public', 'manage-all'],*!/
      } */
      /* user.permissions.push('read-Public')

      user.permissions.push('manage-all') */
      const userAbility = user.permissions.map(item => {
        const actionResource = item.split('-')
        if (actionResource.length > 0) {
          const action = actionResource[0]
          const subject = actionResource[1]

          return { action, subject }
        }

        return { action: item, subject: item }
      })
      userAbility.push({ action: 'read', subject: 'Public' })

      // Set user ability
      // ? https://casl.js.org/v5/en/guide/intro#update-rules
      ability.update(userAbility)

      // Set user's ability in localStorage for Access Control
      localStorage.setItem('userAbility', JSON.stringify(userAbility))

      // We will store `userAbility` in localStorage separate from userData
      // Hence, we are just removing it from user object
      delete user.permissions
      state.user = user

      // Set user's data in localStorage for UI/Other purpose
      localStorage.setItem('userData', JSON.stringify(user))
    },
    searchAuthors(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/authors', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchAuthor(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/authors/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDashboard({ state }, options) {
      axios
        .get('/dashboard', { params: options })
        .then(response => {
          if (response.data && response.data.length > 0) {
            state.dashboards = response.data
            localStorage.setItem('lbe@dashboards', JSON.stringify(response.data))
          }
        })
        .catch(error => console.log(error))
    },
    fetchCountries({ state }, options) {
      axios
        .get('/countries', { params: options })
        .then(response => {
          state.pays = response.data.data
          localStorage.setItem('lbe@pays', JSON.stringify(response.data.data))
        })
        .catch(error => console.log(error))
    },
    fetchStatuses({ state }, options) {
      axios
        .get('/statuses', { params: options })
        .then(response => {
          state.statuses = response.data.data
          localStorage.setItem('lbe@statuses', JSON.stringify(response.data.data))
        })
        .catch(error => console.log(error))
    },
    fetchSources({ state }, options) {
      axios
        .get('/sources', { params: options })
        .then(response => {
          state.sources = response.data.data
          localStorage.setItem('lbe@sources', JSON.stringify(response.data.data))
        })
        .catch(error => console.log(error))
    },
    fetchColors({ state }, options) {
      axios
        .get('/colors', { params: options })
        .then(response => {
          if (response.data && response.data.data) {
            state.colors = response.data.data
            localStorage.setItem('lbe@colors', JSON.stringify(response.data.data))
          }
        })
        .catch(error => console.log(error))
    },
    fetchOrigins({ state }, options) {
      axios
        .get('/origins', { params: options })
        .then(response => {
          state.origins = response.data.data
          localStorage.setItem('lbe@origins', JSON.stringify(response.data.data))
        })
        .catch(error => console.log(error))
    },
    fetchGenres({ state }, options) {
      axios
        .get('/genres', { params: options })
        .then(response => {
          state.genres = response.data.data
          localStorage.setItem('lbe@genres', JSON.stringify(response.data.data))
        })
        .catch(error => console.log(error))
    },
    fetchFormats({ state }, options) {
      axios
        .get('/formats', { params: options })
        .then(response => {
          state.formats = response.data.data
          localStorage.setItem('lbe@formats', JSON.stringify(response.data.data))
        })
        .catch(error => console.log(error))
    },
  },
}
